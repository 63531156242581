"use client";

import React from "react";
import { useParams, useRouter } from "next/navigation";
import { Grid, Box } from "@mui/material";
import systemConfig from "@next/config/system.config";
import { ButtonContained, CustomTypography } from "@components/atoms";
import { CustomSearch } from "@components/molecules";
import { PrivateTemplate } from "@components/templates";
import { useTranslation } from "i18n/client";

export const PageNotFound = () => {
  const router = useRouter();
  const params = useParams();
  const {t} = useTranslation("common")
  const handleNavigate = () => {
    router.push(`/${params.lng}`);
  };
 
  return (
    <PrivateTemplate>
      <Grid
        container
        sx={{
          marginTop: "80px",
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            // padding:"176px 0px",
            alignSelf: "center",
            paddingLeft: "80px",
          }}
        >
          <Box>
            <Box
              sx={{
                marginBottom: "12px",
              }}
            >
              <CustomTypography
                color="#d12779"
                fontsize="16px"
                fontweight="600"
                lineheight="1.5"
                textalign="left"
              >
                404 error
              </CustomTypography>
            </Box>
            <Box
              sx={{
                marginBottom: "12px",
              }}
            >
              <CustomTypography
                color="#101828"
                fontsize="60px"
                fontweight="600"
                lineheight="1.2"
                textalign="left"
              >
                {t("page_not_found.title")}
              </CustomTypography>
            </Box>
            <Box
              sx={{
                marginBottom: "48px",
              }}
            >
              <CustomTypography
                color="#667085"
                fontsize="20px"
                fontweight="normal"
                lineheight="1.5"
                textalign="left"
              >
               {t("page_not_found.subtitle")}
              </CustomTypography>
             
            </Box>
            <Box>
              {systemConfig.NOT_FOUND.HAS_SEARCH_BAR && (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <CustomSearch />
                  <Box
                    sx={{
                      marginLeft: "36px",
                    }}
                  >
                    <ButtonContained id="pageNotFound_search_btn">Search</ButtonContained>
                  </Box>
                </Box>
              )}
              {systemConfig.NOT_FOUND.HAS_HOME_BUTTON && (
                <Box
                  sx={{
                    margin: "0px auto",
                    width: "150px",
                  }}
                >
                  <ButtonContained clickHandler={handleNavigate}>
                  {t("page_not_found.home")}
                  </ButtonContained>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={t("page_not_found.assets.images.image_one.file")}
              alt={t("page_not_found.assets.images.image_one.alt")}
              title={t("page_not_found.assets.images.image_one.title")}
              sx={{
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </PrivateTemplate>
  );
};
